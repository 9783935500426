import GlobalLayout from "components/page/GlobalLayout";
import PageContent from "components/page/PageContent";
import React from "react";

import html from "../assets/privacy.html";

export default function Privacy() {
  return (
    <GlobalLayout>
      <PageContent>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
          style={{ margin: "50px 20px" }}
        />
      </PageContent>
    </GlobalLayout>
  );
}
